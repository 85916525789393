import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import './index.less';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { fetchHairstyleRecordsBalanceData } from '@/lib/service';
import { useQuery } from '@tanstack/react-query';
import Head from 'next/head';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  bgColor?: string;
}

interface TdkData {
  title: string;
  description: string;
  keywords: string;
}

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname, locale } = useRouter();
  const { isMobile } = useConfigProvider();
  const { isLogin, isShowGiftView, onShowGiftView } = useUserProvider();
  const { t } = useTranslation('common');

  const loadBalanceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onShowGiftView(res?.data?.has_survey_reward);
        }
      })
      .catch((e) => {
        // message.error(e?.message);
      });
  };

  const { data: tdkData, isLoading } = useQuery<TdkData>({
    queryKey: ['tdk', pathname, locale],
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
  });
  useEffect(() => {
    loadBalanceList();
  }, [showHeader, isLogin]);

  const tdkContent = useMemo(() => {
    if (Object.keys(tdkData).length === 0) return null;

    return (
      <Head>
        {tdkData?.title && <title key="title">{tdkData?.title}</title>}
        {tdkData?.description && (
          <meta key="description" name="description" content={tdkData?.description} />
        )}
        {tdkData?.keywords && <meta key="keywords" name="keywords" content={tdkData?.keywords} />}
      </Head>
    );
  }, [tdkData]);

  // 处理 head 中的 TDK，移除其中的 title 和 meta 标签
  const processedHead = useMemo(() => {
    if (!head || Object.keys(tdkData).length === 0) return head;

    // 如果 head 是 React 元素，需要处理其子元素
    const headElement = head as React.ReactElement;
    if (headElement.type === Head) {
      const children = React.Children.toArray(headElement.props.children);
      // 过滤掉 title 和 指定的 meta 标签
      const filteredChildren = children.filter((child) => {
        if (!React.isValidElement(child)) return true;
        if (child.type === 'title' && tdkData.title) {
          return false;
        }
        if (child.type === 'meta') {
          if (child.props.name === 'description' && tdkData.description) {
            return false;
          }
          if (child.props.name === 'keywords' && tdkData.keywords) {
            return false;
          }
        }
        return true;
      });

      return <Head>{filteredChildren}</Head>;
    }
    return head;
  }, [head, tdkData]);
  // app sub title
  const getNavItems = () => {
    return [
      {
        text: t('Influencers Square'),
        path: '/influencers',
      },
      {
        text: t('Plan'),
        path: '/plan',
      },
      {
        text: t('Hair Fashion'),
        path: '/blog',
        dropdownItems: [
          {
            text: t('Female'),
            path: '/category/female',
          },
          {
            text: t('Male'),
            path: '/category/male',
          },
        ],
      },
      {
        text: 'FAQ',
        path: '/faq',
      },
      {
        text: 'API',
        path: '/hairpaca-api',
      },
    ];
  };

  return (
    <div className={'layout'}>
      {processedHead}
      {tdkContent}
      {showHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={isMobile}
            isMarketplaceHome={pathname === '/'}
            navItems={[...getNavItems()]}
          />
        </header>
      )}
      <main
        className={classNames(
          'main',
          isMobile ? (isShowGiftView ? 'topBanner' : 'mobile') : isLogin ? '' : 'topBanner',
          showHeader ? '' : 'hiddenTopBanner',
        )}
      >
        {children}
      </main>
      {showFooter && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
