import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import UserProvider from '@/context/UserProvider';
import withTheme from '@/theme';
import '../lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
import {
  dehydrate,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import LoginResult from '@/components/LoginResult';
import { getTdkApi } from '@/lib/service/tdk';

const ArticleBottomAlert = dynamic(() => import('@/components/Common/ArticleBottomAlert'), {
  ssr: false,
});
// const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
//   ssr: false,
// });
const Login = dynamic(() => import('@/components/Login'), {
  ssr: false,
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/hs-theme.less');

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<
  P = {
    dehydratedState?: unknown;
  },
> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};
const queryClient = new QueryClient();

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              {getLayout(<Component {...pageProps} />)}
            </HydrationBoundary>
          </QueryClientProvider>
          <Login />
          <LoginResult />
          <ArticleBottomAlert />
          {/* <CookieConsentContent /> */}
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}
App.getInitialProps = async ({ ctx }: any) => {
  const queryClient = new QueryClient();

  // 预取 TDK 数据
  await queryClient.prefetchQuery({
    queryKey: ['tdk', ctx.pathname, ctx.locale],
    queryFn: async () => {
      const res = await getTdkApi(ctx.pathname, ctx.locale);
      return res.data ?? {};
    },
  });

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};
export default appWithTranslation(App);
