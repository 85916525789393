import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from '@/pages/_app';
import React, { useEffect, useState } from 'react';
import Layout from '../layouts';
import { AppSettings, lowerName } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getHairstyleCountData, getHomeIndexUpInfoListApi } from '@/lib/service';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import RegisterSuccessAlert from '@/components/Common/RegisterSuccessAlert';
import { useTranslation } from 'next-i18next';
import CustomHead from '@/components/Common/CustomHead';
import { canonicalUrl } from '@/shared/utils';

const HairStyleContent = dynamic(() => import('@/components/HairStyleHome'), {
  ssr: false,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl, defaultLocale, query } = context;
  // console.log("query:",query);
  const upsListRes = await getHomeIndexUpInfoListApi(3, locale)
  const hairstyleCountRes = await getHairstyleCountData()
  const localeRes = await serverSideTranslations(locale, ['common']);
  // const currentURL = `https://${req.headers.host}${resolvedUrl}`;
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      ...localeRes,
      upsListRes: { ...(upsListRes ?? {}) },
      hairstyleCount: hairstyleCountRes?.data,
      herf: canonicalUrl(req.headers.host, resolvedUrl, locale),
      title: tempData[`${lowerName}_meta_Title`],
      description: tempData[`${lowerName}_meta_Desc`],
      hasSurveyReward: Boolean(query.survey_reward),
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props: any) => {
  const [showAlert, setShowAlert] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('common');
  useEffect(() => {
    if (props.hasSurveyReward) {
      setShowAlert(true);
    }
  }, [props.hasSurveyReward]);
  return (
    <div>
      <HairStyleContent data={props} />
      <RegisterSuccessAlert
        openType={showAlert}
        title={t('Thanks for completing the feedback form!')}
        desc={<>
          <div style={{ color: '#68F3F6' }}>{t("You have received 5 free try-ons.")}</div>
          <div style={{ color: 'var(--app-text-color)' }}>{t("Try Out Some Trendy Hairstyles Now!")}</div>
        </>}
        btnTitle1={t('EXPLORE TRENDY STYLES')}
        handleCancel={() => {
          setShowAlert(false)
        }}
        handleClick1={() => {
          router.push('/influencers?type=0')
          setShowAlert(false)
        }}
      />
    </div>
  );
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title ?? '';
  const desc = page?.props?.description ?? '';

  return (
    <Layout
      head={
        <CustomHead>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
        </CustomHead>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
