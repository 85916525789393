// import { useTranslation } from "next-i18next"
export const isProd =
  process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

// App 1:DRfans, 2:Quotesecho
const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = true;

// name
const getAppName = () => {
  return 'Hairstyle';
};

export const lowerName = getAppName().toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `https://gpts-prod.s3.us-west-1.amazonaws.com/logo/${lowerName}-logo.png`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;
// app title
const getAppTitle = () => {
  return '';
};

// app desc
const getAppDesc = () => {
  return ``;
};

// app sub title
const getAppHeadSubTitle = () => {
  return '';
};

export const currentHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `www-test.hairpaca.net`;
  } else {
    return `www.hairpaca.net`;
  }
};

//
const getFirebaseConfig = () => {
  return {
    apiKey: 'AIzaSyD5R0hVDPXn_eHlYNtLz56nIuikaIVK-PM',
    authDomain: `${currentHost()}/fb-auth`,
    projectId: 'hairstyle-807dd',
    storageBucket: 'hairstyle-807dd.appspot.com',
    messagingSenderId: '833562964907',
    appId: '1:833562964907:web:cc66d63e8a5ac4259b18ee',
    measurementId: 'G-ER3EG4VR8L',
  };
};

// link
const getClarityId = () => {
  return 'm83n7x2p8h';
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  clarityId: isProd ? getClarityId() : 'l9q3tnv9s4',
};

export const letterList = [
  {
    title: 'A',
    link: `/baby-name-search?letter=A`,
  },
  {
    title: 'B',
    link: `/baby-name-search?letter=B`,
  },
  {
    title: 'C',
    link: `/baby-name-search?letter=C`,
  },
  {
    title: 'D',
    link: `/baby-name-search?letter=D`,
  },
  {
    title: 'E',
    link: `/baby-name-search?letter=E`,
  },
  {
    title: 'F',
    link: `/baby-name-search?letter=F`,
  },
  {
    title: 'G',
    link: `/baby-name-search?letter=G`,
  },
  {
    title: 'H',
    link: `/baby-name-search?letter=H`,
  },
  {
    title: 'I',
    link: `/baby-name-search?letter=I`,
  },
  {
    title: 'J',
    link: `/baby-name-search?letter=J`,
  },
  {
    title: 'K',
    link: `/baby-name-search?letter=K`,
  },
  {
    title: 'L',
    link: `/baby-name-search?letter=L`,
  },
  {
    title: 'M',
    link: `/baby-name-search?letter=M`,
  },
  {
    title: 'N',
    link: `/baby-name-search?letter=N`,
  },
  {
    title: 'O',
    link: `/baby-name-search?letter=O`,
  },
  {
    title: 'P',
    link: `/baby-name-search?letter=P`,
  },
  {
    title: 'Q',
    link: `/baby-name-search?letter=Q`,
  },
  {
    title: 'R',
    link: `/baby-name-search?letter=R`,
  },
  {
    title: 'S',
    link: `/baby-name-search?letter=S`,
  },
  {
    title: 'T',
    link: `/baby-name-search?letter=T`,
  },
  {
    title: 'U',
    link: `/baby-name-search?letter=U`,
  },
  {
    title: 'V',
    link: `/baby-name-search?letter=V`,
  },
  {
    title: 'W',
    link: `/baby-name-search?letter=W`,
  },
  {
    title: 'X',
    link: `/baby-name-search?letter=X`,
  },
  {
    title: 'Y',
    link: `/baby-name-search?letter=Y`,
  },
  {
    title: 'Z',
    link: `/baby-name-search?letter=Z`,
  },
  // {
  //   title: 'All',
  //   link: `/baby-name-search?letter=other-letters`,
  // },
];
